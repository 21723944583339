<template>
    <EstudianteObservador />
</template>
<script>
import EstudianteObservador from "@/components/EstudianteObservador.vue";
export default({
     components:{
        EstudianteObservador
    }
})
</script>