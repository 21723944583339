<template>
    <RegistroEstudiantes />
</template>
<script>
import RegistroEstudiantes from "@/components/RegistroEstudiantes.vue";
export default({
     components:{
        RegistroEstudiantes
    }
})
</script>