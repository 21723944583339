<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Grupos"
          item-text="grupo.nombre"
          item-value="grupo.id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="secondary" @click="consultar">Consultar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    periodoId: 0,
    grupoId: 0,
    periodos: [],
    grupos: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    urlPlantilla() {
      return `api/PlantillasNotas/informeConsolidado/periodo/${this.periodoId}/grupo/${this.grupoId}`;
    },
  },
  methods: {
    ...mapActions(["GetPeriodos", "GetGruposDirector", "DescargarConsolidado"]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    consultar() {
      axios
        .get(this.urlPlantilla, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let nombreArchivo = response.headers["content-disposition"]
            .split(";")[1]
            .replace('filename=', "");
          nombreArchivo = nombreArchivo
            .slice(0, nombreArchivo.length)
            .replaceAll(" ", "");
          nombreArchivo = nombreArchivo.replaceAll("-", "_");
          nombreArchivo = nombreArchivo.replaceAll('-"', "");
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = nombreArchivo;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  watch: {
    periodoId(nuevoValor) {
      if (nuevoValor > 0) {
        this.GetGruposDirector(nuevoValor).then((x) => (this.grupos = x.data));
      } else {
        this.grupos = [];
        this.grupoId = 0;
      }
    },
  },
};
</script>