<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="observaciones"
          :items-per-page="5"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    observaciones: [],
    headers: [
      { text: "Fecha", value: "fechaRegistro" },
      { text: "Docente", value: "nombreDocente" },
      { text: "Observación", value: "descripcion" },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["GetObservadorEstudianteSesion"]),
    initialize() {
      this.GetObservadorEstudianteSesion().then(
        (x) => (this.observaciones = x.data)
      );
    },
  },
};
</script>