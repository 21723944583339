<template>
  <v-data-table
    :headers="headers"
    :items="usuarios"
    :items-per-page="5"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title> Administración de estudiantes </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template>
          <v-dialog persistent v-model="dialogUsuarios" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Adicionar
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Nuevo usuario</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :disabled="editedIndex > -1"
                        v-model="editedItem.email"
                        label="Correo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.nombres"
                        label="Nombres"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.apellidos"
                        label="Apellidos"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.tipoIdentificacion"
                        label="Tipo documento"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.identificacion"
                        label="No documento"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.password"
                        label="Constraseña"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="editedItem.estado"
                        :label="editedItem.estado ? 'Activo' : 'Retirado'"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <v-autocomplete
                                  v-model="editedItem.anual"
                                  :items="anual"
                                  item-text="fechaAnual"
                                  item-value="id"
                                  label="Año"
                                ></v-autocomplete>
                              </th>
                              <th class="text-left">
                                <v-autocomplete
                                  v-model="editedItem.grupo"
                                  :items="grupos"
                                  item-text="nombre"
                                  item-value="id"
                                  label="Grupo"
                                ></v-autocomplete>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in gruposAsignados" :key="index">
                              <td>{{ item.fechaAnual }}</td>
                              <td>{{ item.nombre }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogUsuarios = false"
                >
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Actualizar </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    dialogUsuarios: false,
    search: "",
    usuarios: [],
    grupos: [],
    gruposAsignados: [],
    headers: [
      {
        text: "Correo",
        align: "start",
        value: "email",
      },
      { text: "Nombre", value: "nombres" },
      { text: "Apellido", value: "apellidos" },
      { text: "Tipo Doc", value: "tipoIdentificacion" },
      { text: "No. Doc", value: "identificacion" },
      { text: "Estado", value: "nombreEstado" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      email: "",
      nombres: "",
      apellidos: "",
      estado: true,
      password: "",
      grupo:-1,
      anual:-1,
      tipoIdentificacion:"",
      identificacion:""
    },
    defaultItem: {
      id: "",
      email: "",
      nombres: "",
      apellidos: "",
      estado: true,
      password: "",
      grupo:-1,
      anual:-1,
      tipoIdentificacion:"",
      identificacion:""
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["anual"]),
  },
  watch: {
    dialogUsuarios(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions([
      "GetUsuariosEstudiante",
      "GetGrupos",
      "RegistrarUsuarioEstudiante",
      "UpdateUsuarioEstudiante",
      "GetAnuales",
      "GetGruposEstudiante",
    ]),
    initialize() {
      this.GetUsuariosEstudiante().then((x) => (this.usuarios = x.data));
      this.GetGrupos().then((x)=> this.grupos = x.data);
      this.GetAnuales();
    },
    editItem(item) {
      this.editedIndex = this.usuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.grupo = -1;
      this.editedItem.anual = -1;
      this.GetGruposEstudiante(item.id).then((x)=> this.gruposAsignados = x.data);
      this.dialogUsuarios = true;
    },
    save() {
      if (this.editedIndex > -1) {
        //editar
        debugger
        let item = this.editedItem;
        delete item.nombreEstado;
        this.UpdateUsuarioEstudiante(this.editedItem).then((x) => {
          Object.assign(this.usuarios[this.editedIndex], x.data);
          this.close();
        });
      } else {
        //nuevo
        this.RegistrarUsuarioEstudiante(this.editedItem).then((x) => {
          const respuesta = x.data;
          if (!!respuesta) {
            this.usuarios.push(respuesta);
            this.close();
          }
        });
      }
    },
    close() {
      this.dialogUsuarios = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.gruposAsignados = [];
      });
    },
  },
};
</script>