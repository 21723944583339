<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Grupo"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Asignaturas"
          item-text="nombre"
          item-value="id"
          :items="asignaturas"
          v-model="asignaturaId"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Estudiante"
          item-text="nombre"
          item-value="id"
          :items="estudiantes"
          v-model="estudianteId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="RendimientosFiltro"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn
              :disabled="item.nota >= 3"
              small
              icon
              color="secondary"
              @click="habilitar(item.estudianteId,item.asignaturaId)"
            >
              <v-icon dark> mdi-restore </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template> 

<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    periodoId: 0,
    grupoId: 0,
    estudianteId: "0",
    asignaturaId: 0,
    periodos: [],
    grupos: [],
    estudiantes: [],
    asignaturas: [],
    estudiantesHabilitacion: [],
    headers: [
      { text: "Asignatura", value: "nombreAsignatura" },
      { text: "Estudiante", value: "nombreEstudiante" },
      { text: "Nota", value: "nota" },
      { text: "Recuperar", value: "acciones", sortable: false },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetGruposDocente",
      "ModificarMensaje",
      "GetEstudiantesHabilitar",
      "UpdateHabilitarAsignatura",
    ]),
    initialize() {
      this.GetPeriodos().then(
        (x) => (this.periodos = x.data.filter((x) => x.estado == 1))
      );
    },
    consultarEstudiantesHabilitar() {
      let datos = {};
      datos.periodo = this.periodoId;
      datos.grupo = this.grupoId;
      this.GetEstudiantesHabilitar(datos).then((x) => {
        this.estudiantesHabilitacion = x.data;
        this.estudiantes = this.obetnerFiltros(
          this.estudiantesHabilitacion,
          "estudianteId",
          "nombreEstudiante",
          "Todos",
          "0"
        );

        this.asignaturas = this.obetnerFiltros(
          this.estudiantesHabilitacion,
          "asignaturaId",
          "nombreAsignatura",
          "Todos",
          0
        );
      });
    },
    obetnerFiltros(datos, id, nombre, textoDefault, idDefault) {
      const filteredArr = datos.reduce((acc, current) => {
        let dato = {
          id: current[id],
          nombre: current[nombre],
        };

        const x = acc.find((item) => item.id === dato.id);
        if (!x) {
          return acc.concat([dato]);
        } else {
          return acc;
        }
      }, []);

      var merged = [].concat.apply(
        [{ id: idDefault, nombre: textoDefault }],
        filteredArr
      );

      return merged;
    },
    habilitar(estudiante,asignatura){
       let datos ={};
       datos.estudianteId = estudiante;
       datos.periodoId = this.periodoId;
       datos.asignaturaId = asignatura;
       datos.grupoId = this.grupoId;
      this.UpdateHabilitarAsignatura(datos).then((x)=>{
         
         let index = this.estudiantesHabilitacion.findIndex((item)=>{
           return item.asignaturaId == datos.asignaturaId && item.estudianteId == datos.estudianteId
         });
         if(index > -1) this.estudiantesHabilitacion[index].nota = 3

         this.ModificarMensaje({texto:'Registro Exitoso!'})
      });
    }
  },
  computed: {
    RendimientosFiltro() {
      if (this.estudianteId !== "0" || this.asignaturaId !== 0) {
        if (this.estudianteId !== "0" && this.asignaturaId !== 0) {
          return this.estudiantesHabilitacion.filter((x) => {
            return (
              x.estudianteId === this.estudianteId &&
              x.asignaturaId === this.asignaturaId
            );
          });
        } else {
          return this.estudiantesHabilitacion.filter((x) => {
            return (
              x.estudianteId === this.estudianteId ||
              x.asignaturaId === this.asignaturaId
            );
          });
        }
      } else {
        return this.estudiantesHabilitacion;
      }
    },
  },
  watch: {
    periodoId(nuevoValor) {
      this.grupoId = 0;
      this.rendimientos = [];
      this.GetGruposDocente(nuevoValor).then((x) => (this.grupos = x.data));
    },
    grupoId(nuevoValor) {
      if (nuevoValor > 0) {
        this.consultarEstudiantesHabilitar();
      }
    },
  },
};
</script>