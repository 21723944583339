<template>
    <DirectorConsolidacionNotas></DirectorConsolidacionNotas>
</template>
<script>
import DirectorConsolidacionNotas from "@/components/DirectorConsolidacionNotas.vue"
export default {
    components:{
        DirectorConsolidacionNotas,

    }
}
</script>