<template>
    <HabilitarEstudiante></HabilitarEstudiante>
</template>
<script>
import HabilitarEstudiante from "@/components/HabilitarEstudiante.vue"
export default {
    components:{
        HabilitarEstudiante,

    }
}
</script>